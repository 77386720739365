* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#modal-root {
  z-index: 999999;
  position: relative;
}

.demo-image-wrapper:hover .demo-btn {
  opacity: 1;
  transform: translate(-50%, 0);
}

div.DraftEditor-root {
  padding: 10px 10px;
  background-color: white;
  min-height: 300px;
  width: 100%;
  overflow-y: auto;
}

div.DraftEditor-editorContainer {
  height: 100%;
}

div.public-DraftEditor-content {
  min-height: 300px;
}
